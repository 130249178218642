import React from 'react'
import styled, { css } from 'styled-components'
import { createFragmentContainer, graphql } from 'react-relay'

import { breakpointSmallPlusAndBelow } from '@StyledComponents/theme/helpers/breakpoints'
import TeacherTypes from '@types/Teacher.types'
import SimilarProfessorListItem from './SimilarProfessorListItem'

const MIN_PROFS = 2

export const SimilarProfessorsTitle = styled.h3`
  font-family: ${props => props.theme.fontFamily.poppins};
  font-size: ${props => props.theme.fontSize.medium};
  font-weight: ${props => props.theme.fontWeight.medium};
  padding-left: 35px;
  padding-top: 15px;
  text-align: left;
`

export const StyledSimilarProfessorsList = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 15px;
  padding-left: 35px;
  padding-top: 15px;
  width: 100%;

  ${breakpointSmallPlusAndBelow(css`
    li:nth-child(3) {
      display: none;
    }
  `)}
`

const SimilarProfessorsContainer = styled.div`
  background-color: ${props => props.theme.color.transparentBlue};
  margin-top: 17px;
  width: 464px;
  ${breakpointSmallPlusAndBelow(css`
    width: 324px;
  `)}
`

export function SimilarProfessors({ teacher }) {
  if (!teacher || !teacher.relatedTeachers || teacher.relatedTeachers.length < MIN_PROFS) {
    return null
  }

  return (
    <SimilarProfessorsContainer>
      <SimilarProfessorsTitle>Similar Professors</SimilarProfessorsTitle>
      <StyledSimilarProfessorsList>
        {teacher.relatedTeachers.map(prof => (
          <SimilarProfessorListItem key={prof.legacyId} teacher={prof} />
        ))}
      </StyledSimilarProfessorsList>
    </SimilarProfessorsContainer>
  )
}

SimilarProfessors.propTypes = {
  teacher: TeacherTypes
}

export default createFragmentContainer(SimilarProfessors, {
  teacher: graphql`
    fragment SimilarProfessors_teacher on Teacher {
      department
      relatedTeachers {
        legacyId
        ...SimilarProfessorListItem_teacher
      }
    }
  `
})
