import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import ReactRouterPropTypes from 'react-router-prop-types'
import { applyBreakpointMin } from '@StyledComponents/theme/helpers/breakpoints'
import PageWrapper from '@components/PageWrapper'

const StyledOutbrainAd = styled.aside`
  ${applyBreakpointMin(
    'large',
    css`
      max-width: calc(
        ${props => props.theme.pageWrapper.maxWidth}px - ${props => props.theme.adRail.width}px
      );
    `
  )}
`

export default function OutbrainAd({ history }) {
  const srcUrl = `https://www.ratemyprofessors.com${history.location.pathname}`

  useEffect(() => {
    if (window.OBR) {
      window.OBR.extern.reloadWidget()
      return undefined
    }

    const script = document.createElement('script')
    script.id = 'rmp-outbrain-script'
    script.src = 'https://widgets.outbrain.com/outbrain.js'
    script.type = 'text/javascript'
    script.async = true

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [history.location.pathname])

  return (
    <PageWrapper>
      <StyledOutbrainAd>
        <div
          id="rmp-outbrain-target"
          data-website-id="rmp"
          className="OUTBRAIN"
          data-src={srcUrl}
          data-widget-id="AR_1"
        />
      </StyledOutbrainAd>
    </PageWrapper>
  )
}

OutbrainAd.propTypes = {
  history: ReactRouterPropTypes.history
}
