import { Power1 } from 'gsap'

const Z_INDEX_MULTIPLIER = 10000
const Z_INDEX_MAX = 2147483647

const theme = {
  fontFamily: {
    poppins: 'Poppins, sans-serif',
    helvetica: 'Helvetica, arial',
    helveticaNeue: 'HelveticaNeue, arial',
    avenirHeavy: 'Avenir-Heavy, Avenir, Helvetica, sans-serif',
    avenir: 'Avenir, Helvetica, sans-serif'
  },
  fontSize: {
    small: '12px',
    default: '14px',
    medium: '16px',
    large: '18px',
    teacherCardName: '20px',
    teacherNotes: '22px',
    // TODO: sort out naming for this and possibly reduce number of font-sizes
    xlarge: '26px',
    xxlarge: '30px',
    large3x: '32px',
    large4x: '50px',
    huge: '60px',
    jumbo: '80px',
    gigantic: '180px'
  },
  fontWeight: {
    normal: '400',
    light: '500',
    medium: '600',
    bold: '700',
    bolder: '800',
    boldest: '900'
  },
  color: {
    default: '#151515',
    error: '#ff5959',
    formError: '#ff0000',
    darkDefault: '#000000',
    fadedDefault: '#989898',
    mainBlue: '#0055fd',
    darkMainBlue: '#0021ff',
    transparentBlue: 'rgba(217, 232, 255, 0.61)',
    inputSelect: '#1da1f2',
    blobBlue: '#e6f7ff',
    cyanBlue: '#00A3FF',
    lightBlue: '#d9e8ff',
    lightBlue2: '#ecf3ff',
    lightBlue3: '#00a3ff',
    lime: '#8ECF6F',
    green: '#7ff6c3',
    successGreen: '#6cdb77',
    yellow: '#fff170',
    red: '#ff9c9c',
    errorRed: '#FF5959',
    softRed: '#EB5757',
    pink: '#FF5959',
    black: '#000000',
    white: '#ffffff',
    gray: '#f1f1f1',
    gray2: '#dddddd',
    gray3: '#f3f3f3',
    gray4: '#d1d1d1',
    gray5: '#e4e4e4',
    gray6: '#f9f9f9',
    gray7: '#f7f7f7',
    gray8: '#4e4e4e',
    gray9: '#9e9e9e',
    gray10: '#313131',
    gray11: '#7E7E7E',
    gray12: '#efefef',
    gray13: '#fafafa',
    gray14: '#cccccc',
    gray15: '#e5e5e5',
    gray16: '#eeeeee',
    gray17: '#7d7d7d',
    transparentBackgroundGray: 'rgba(69, 69, 69, 0.5);',
    ratingLevelColors: {
      QUALITY: {
        1: '#EB5757',
        2: '#F2994A',
        3: '#F2C94C',
        4: '#8ECF6F',
        5: '#219653'
      },
      DIFFICULTY: {
        1: '#219653',
        2: '#8ECF6F',
        3: '#F2C94C',
        4: '#F2994A',
        5: '#EB5757'
      }
    },
    awesomeScore: '#7FF6C3',
    averageScore: '#FFF170',
    awfulScore: '#FF9C9C',
    noScore: '#EEEEEE',
    thumbGreen: '#7AFBA9'
  },
  breakpoints: {
    xsmall: 0,
    tiny: 360,
    small: 496,
    smallplus: 680,
    smedium: 768,
    medium: 984,
    xmedium: 1024,
    large: 1224,
    largeplus: 1280,
    xlarge: 1440
  },
  zIndex: {
    // TODO: Make this less ridiculous
    stickyHeader: 6 * Z_INDEX_MULTIPLIER,
    search: 2 * Z_INDEX_MULTIPLIER,
    tooltip: 3 * Z_INDEX_MULTIPLIER,
    adRail: 4 * Z_INDEX_MULTIPLIER,
    videoAnchor: 5 * Z_INDEX_MULTIPLIER,
    stickyLeaderboard: 21 * Z_INDEX_MULTIPLIER,
    header: 25 * Z_INDEX_MULTIPLIER,
    footer: 5 * Z_INDEX_MULTIPLIER,
    footerActive: 30 * Z_INDEX_MULTIPLIER,
    dropdown: 30 * Z_INDEX_MULTIPLIER,
    typeahead: 31 * Z_INDEX_MULTIPLIER,
    helpScout: Z_INDEX_MAX - 10,
    fullPageModal: Z_INDEX_MAX
  },
  padding: {
    teacherInfo: '22px',
    textFieldH: 16,
    textFieldV: 12
  },
  mainColumnWidth: {
    medium: '755px',
    smedium: '688px',
    small: '375px',
    form: '360px'
  },
  header: {
    height: 92
  },
  leaderboard: {
    height: 148
  },
  adRail: {
    width: 354,
    margin: 17
  },
  anim: {
    footer: {
      length: 0.25,
      easing: 'ease-in-out',
      gsapEasing: Power1.easeInOut
    }
  },
  bkgrdGraphicOffsets: {
    yellowBlob: {
      x: 'max(100%,63vw)',
      y: 'max(-1200px,calc(40px + 108%))'
    },
    blueBlob: {
      top: '300px',
      right: '190px'
    },
    pinkBlob: {
      top: '1020px',
      left: '575px'
    }
  },
  pageWrapper: {
    maxWidth: 1224,
    headerWidth: 1440
  },
  homeAds: {
    height: '320px',
    width: '550px'
  }
}

export default theme
